import React, {useState} from 'react';
import './App.css';

declare const CONFIG: { api_host: string };

function App() {
  const [fact, setFact] = useState<string | null>(null);

  const handleGetFact = async () => {
    try {
      const response = await fetch(CONFIG.api_host);
      const data = await response.json();
      setFact(data.facts[0]);
    } catch (error) {
      console.error('Error fetching dog fact:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Welcome to Wrkr Magic!
        </p>
        <button onClick={handleGetFact}>Get Dog Fact</button>
        <div id="fact">
        {fact !== null ? (
          <p>{fact}</p>
        ) : (
          <p>Click "Get Dog Fact" to fetch a dog fact</p>
        )}
      </div>
      </header>
    </div>
  );
}

export default App;
